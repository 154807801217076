.body {
  display: grid;
  grid-template-columns: 65vw 25vw;
  margin: 0 auto;
  justify-content: center;
}



.aside {
  background-color: transparent;
  margin-top: 2rem;
width: calc(11.875rem * 1.5);

}


@media screen and (max-width:1024px) {
  .body {
  grid-template-columns: 1fr 1fr;
}

}

@media screen and (max-width:600px) {
.body {
  grid-template-columns: 1fr;
}

.aside{
    display: none;
}
}
  