.PostsCard, .youtubevideo{
  padding: 2rem;
  background-color: var(--white);
  margin: 2rem 2rem 4rem 2rem;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 17px 20px 14px 0px rgba(0, 0, 0, 0.1);
  width: 55vw;
}

.youtubevideo{
 display: flex;
 flex-direction: column;
 gap: 1.5rem;
}

.youtubevideo iframe{
height: 60vh;
}

.youtubevideo h1:first-of-type{
 font-weight: bolder;
 text-align: center;
 font-size: 2rem;
}

.youtubevideo h1:last-of-type{
 font-size: 1.5rem;
 letter-spacing: 2px;
 text-align: justify;
 font-style: italic;
}

.youtubevideo h1:first-of-type svg:first-of-type{
  position: relative;
  left: calc(65vw/5);
}

.PostsCardTitle {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  padding: 1rem;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.PostsCardImg {
  margin: 2rem auto;
    width: 100%;
  height: max-content;
}

.PostsCardDesc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 1rem;
  font-style: italic;
}

/* From uiverse.io */
.learn-more {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  width: 17rem;
  height: auto;
  margin-top: 1rem;
}

button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
}

button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

button:hover .circle {
  width: 100%;
}

button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

button:hover .button-text {
  color: #fff;
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 600px) {
  .PostsCard {
    width: 90vw;
  }

  .youtubevideo{
    width: 90vw;
  }

  .youtubevideo > iframe{
    height: 100%;
}
}
