
/* From uiverse.io by @alexruix */
/*Magic card*/
.card {
  width: 100%;
  height: calc(15.875rem * 1.2);
  background: #f5f5f5;
  border-radius: 10px;
  overflow: visible;
  box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

.card-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  padding: 0 1rem;
}

.card-img {
  --size: 100px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  transform: translateY(-50%);
  background: #42caff;
  position: relative;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
}

.card-img::before {
  content: "";
  border-radius: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 90%;
  transform: translate(-50%, -50%);
  border: 1rem solid #e8e8e8;
}

/*Text*/
.text-title {
  text-transform: uppercase;
  font-size: 0.75em;
  color: #42caff;
  letter-spacing: 0.05rem;
}

.text-body {
  font-size: 0.8em;
  text-align: center;
  color: #6f6d78;
  font-weight: 400;
  font-style: italic;
}

/*Hover*/
.card:hover .card-img {
  --size: 110px;
  width: var(--size);
  height: var(--size);
}

/*Social Media*/

@import "//codepen.io/chrisdothtml/pen/ojLzJK.css";
.social-btns .btn,
.social-btns .btn:before,
.social-btns .btn .fa {
  transition: all 0.35s;
  transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
}
.social-btns .btn:before {
  top: 90%;
  left: -110%;
}
.social-btns .btn .fa {
  transform: scale(0.8);
}

.social-btns .btn.google:before {
  background-color: #dc4a38;
}
.social-btns .btn.google .fa {
  color: #dc4a38;
}

.social-btns .btn:focus:before,
.social-btns .btn:hover:before {
  top: -10%;
  left: -10%;
}
.social-btns .btn:focus .fa,
.social-btns .btn:hover .fa {
  color: #fff;
  transform: scale(1);
}
.social-btns {
  height: 90px;
  margin: auto;
  font-size: 0;
  text-align: center;
  display: flex;
  justify-content: center;
}
.social-btns .btn {
  display: inline-block;
  background-color: #fff;
  width: 90px;
  height: 90px;
  line-height: 90px;
  margin: 0 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 28%;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  opacity: 0.99;
}
.social-btns .btn:before {
  content: "";
  width: 120%;
  height: 120%;
  position: absolute;
  transform: rotate(45deg);
}
.social-btns .btn .fa {
  font-size: 38px;
  vertical-align: middle;
}


/*Help*/

/* From uiverse.io by @joe-watson-sbf */
.HelpCard {
  width: 100%;
  height: 120px;
  margin: 3rem auto;
  transition: all .5s;
  box-shadow: 15px 15px 30px rgba(25, 25, 25, 0.11),
             -15px -15px 30px rgba(60, 60, 60, 0.082);
  text-align: center;
  overflow: hidden;
}

.HelpCard:hover {
  height: 254px;
  background: linear-gradient(360deg, #edededc5 60%, hsla(0, 0%, 13%, 1) 70%);
}

.HelpCard .HelpCardheader {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  margin-bottom: 16px;
}

.HelpCard .HelpCardheader .img-box > svg{
  width: 2rem;
  height: 2rem;
  color: var(--black);
}

.HelpCard .HelpCardheader .title {
  font-size: 1.1em;
  letter-spacing: .1em;
  font-weight: 500;
  text-transform: uppercase;
  padding: 4px 0 14px 0;
  transition: all .5s;
  color: var(--black);
}

.HelpCard:hover .HelpCardheader {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 96%);
}

.HelpCard:hover .HelpCard .HelpCardheader .title {
  padding: 0;
}

.HelpCard .content {
  display: block;
  text-align: center;
  color: #212121;
  margin: 2rem 18px;
}

.HelpCard .content p {
  transition: all .5s;
  font-size: 1.2em;
  margin-bottom: 8px;
}

.HelpCard .content p p{
  font-size: 1rem;
  margin: 1rem 0;
}

.HelpCard .content a {
  color: #1d8122;
  cursor: pointer;
  transition: all .5s;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  
}

.HelpCard .content .btn-link:hover {
  border-bottom: 1px solid #1d8122;
}


@media screen and (max-width:1024px) {


}

@media screen and (max-width:600px) {
.card{
  width: 65%;
  margin: 2rem auto;
}
}
  
