@import url('https://fonts.googleapis.com/css2?family=DynaPuff&display=swap');

.header {
    margin-bottom: 5rem;
}

.mobileLogo {
    display: none;
}

nav {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

}

nav a {
    align-self: center;
}


nav img {
    height: 15rem;
    width: auto;
    align-self: center;
}

nav a:hover {
    text-decoration: none;
}

nav input {
    height: 2rem;
    width: 80%;
    border: 2px solid var(--black);
    border-radius: 0.5rem;
    align-self: center;
}

nav svg {
    position: relative;
    top: -2rem;
    height: auto;
    width: 20rem;
}

.bars,
.close {
    position: absolute;
    right: 0;
    top: 1rem;
    width: 3rem;
    display: none;
    z-index: 9999;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.close {
    color: rgb(105, 105, 105);
}

.mobile-menu {
    position: absolute;
    transform: translateX(100%);
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    display: none;
    background-color: rgba(0, 0, 0, 0.822);
    width: 100vw;
    height: 100vh;
    z-index: 999;
}

.open {
    transform: translateX(0);
}

.aboutButton,
.help {
    color: whitesmoke;
    width: 100vw;
    height: 3rem;
    background-color: #333;
    border: 1px solid var(--black);
    margin: 0.5rem 0;
    cursor: pointer;

}

.aboutButton {
    margin-top: 10rem;
}



@media screen and (max-width:1024px) {}

@media screen and (max-width:600px) {

    .bars,
    .mobile-menu {
        display: block;
    }

    .mobile-menu {
        display: flex;
        flex-direction: column;
    }

    nav img {
        display: none;
    }

    .mobileLogo {
        display: block;
    }
}