.fullPost{
    display: grid;
    grid-template-columns: 80vw;
    justify-content: center;
}

.fullPostBody{
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    padding: 3rem;
    background-color: var(--white);
  margin: 2rem 2rem 4rem 2rem;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 17px 20px 14px 0px rgba(0, 0, 0, 0.1);
}

.fullPostTitle{
align-self: center;
font-size: 3rem;
font-weight: bold;
letter-spacing: 2px;
}

.fullPostBody > small{
    align-self: center;
    margin-top: 1rem;
    font-size: .9rem;
    font-style: oblique;
}

.fullPostBody > h4{
    
    margin-bottom: 1rem;
    font-size: .9rem;
    font-style: oblique;
}

.fullPostImg{
    align-self: center;
    padding: 2rem;
    width: 50rem;
}

.fullPostDesc{
    align-self: center;
    margin-top: 1rem;
    letter-spacing: 1px;
    line-height: 1.8rem;
    font-size: 1.5rem;
    white-space: pre-wrap;
text-indent: 1.2rem;
}

.donwload {
  padding: 20px 60px;
  background-color: var(--white);
  border: none;
  font-size: 18px;
  position: relative;
  width: max-content;
   overflow: hidden; 
  transition: 500ms;
  margin-top: 2rem;
  text-align: center;
  vertical-align: center;
}

.donwload  span {
  color: black;
  position: relative;
  transition: 500ms;
  transition-delay: 500ms;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
}

.donwload  span > .downloadicon {
  width: 2rem;
  height: 1.5rem;
}

.donwload  div {
  transition: 500ms;
  position: absolute;
  background-color: var(--black);
}

.donwload .top {
  width: 15px;
  height: 2px;
  top: 0;
  left: 0;
}

.donwload .bottom {
  width: 15px;
  height: 2px;
  bottom: 0;
  right: 0;
}

.donwload .left {
  width: 2px;
  height: 15px;
  top: 0;
  left: 0;
}

.donwload .right {
  width: 2px;
  height: 15px;
  bottom: 0;
  right: 0;
}


.donwload:hover:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.donwload:hover .top, 
.donwload:hover .bottom {
  width: 100%;
}

.donwload:hover .left,
.donwload:hover .right {
  height: 100%;
}

@media screen and (max-width:1024px) {
  

}

@media screen and (max-width:600px) {
.fullPost{
    grid-template-columns: 110vw;
}
.fullPostDesc{
  font-size: .8rem;
  width: 90vw;
  text-align: justify;
}
}
  