*{
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  color: inherit;
}

:root{
  --black: #3d3935;
  --white: #ffffff;
  --white-variant: #ecebeb;
  --grey: #c5c4c2;
  --grey-variant: #8b8886;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white-variant);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
